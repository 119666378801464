<template>
  <div class="cards">
    <p class="font-bold">
      Performa Pertumbuhan User (Partner)
    </p>
    <div class="cards-content-row1">
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="@/assets/images/svg/note.svg"
              alt="note"
            >
            <p>Registrasi</p>
          </div>
          <img
            v-b-tooltip.hover.top="'Semua Pendaftar Komship'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.Registrasi.total_register }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.Registrasi.register_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.Registrasi.register_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="@/assets/images/svg/tick-square.svg"
              alt="tick-square"
            >
            <p>Verifikasi</p>
          </div>
          <img
            v-b-tooltip.hover.top="'Total partner yang sudah Verifikasi Email'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.verifikasi.total_verified }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.verifikasi.verified_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.verifikasi.verified_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/onboarding.svg"
              alt="onboarding"
            >
            <p>Onboarding</p>
          </div>
          <img
            v-b-tooltip.hover.top="
              'Total partner yang sudah menyelesaikan onboarding'
            "
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.onboarding.total_onboardig }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.onboarding.onboarding_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.onboarding.onboarding_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/house-2.svg"
              alt="onboarding"
            >
            <p>Add Gudang</p>
          </div>
          <img
            v-b-tooltip.hover.top="
              'Total user yang pernah menambahkan produk di Komship'
            "
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.partner_add_gudang.total_user_gudang }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.partner_add_gudang.gudang_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.partner_add_gudang.gudang_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-content-row2">
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/box-add.svg"
              alt="Komerce"
            >
            <p>Add Produk</p>
          </div>
          <img
            v-b-tooltip.hover.top="'Total partner yang pernah Tambah Order'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.partner_add_product.total }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.partner_add_product.total_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.partner_add_product.total_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/status-up.svg"
              alt="status-up"
            >
            <p>Potensial</p>
          </div>
          <img
            v-b-tooltip.hover.top="'Total partner yang pernah Tambah Order'"
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.potensial.total_potensial }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.potensial.potensial_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.potensial.potensial_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/chart.svg"
              alt="chart"
            >
            <p>Aktif</p>
          </div>
          <img
            v-b-tooltip.hover.top="
              'Total partner yang pernah melakukan Pickup '
            "
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div class="cards-content-data-top-number">
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.aktif.total_user_active }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              +{{ dataPerformance.aktif.active_today }} Hari ini
            </div>
            <div style="color: #08a0f7">
              +{{ dataPerformance.aktif.active_yesterday }} Kemarin
            </div>
          </div>
        </div>
      </div>
      <div class="cards-content-data">
        <div class="cards-content-data-top">
          <div>
            <img
              src="https://storage.googleapis.com/komerce/assets/svg/monitor-mobbile.svg"
              alt="monitor-mobbile"
            >
            <p>Aktif Bulan Ini</p>
          </div>
          <img
            v-b-tooltip.hover.top="
              'Total partner yang pernah melakukan pickup di bulan yang dipilih'
            "
            src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
            alt=""
            class="cursor-pointer"
            variant="outline-primary"
          >
        </div>
        <div
          class="cards-content-data-top-number"
          style="margin-bottom: 10px"
        >
          <div class="cards-content-data-top-number-performance">
            {{ dataPerformance.user_aktif.total_user_aktive }}
          </div>
          <div class="cards-content-data-top-number-analytic">
            <div style="color: #34a770">
              {{ dataPerformance.user_aktif.new_user_active }}% User Baru
            </div>
            <div style="color: #08a0f7">
              {{ dataPerformance.user_aktif.user_active_last_month }}% User Lama
            </div>
          </div>
        </div>
        <vue-monthly-picker
          v-model="filterPerformance.bulan"
          date-format="MMM YYYY"
          place-holder="Pilih Bulan"
          :month-labels="monthlabel"
          :max="maxDatePicker"
          style="margin: auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import VueMonthlyPicker from 'vue-monthly-picker'
import { LABELMONTH } from '@/libs/filterDate'

export default {
  components: {
    VueMonthlyPicker,
  },
  data() {
    return {
      dataPerformance: {},
      filterPerformance: {
        bulan: this.$moment(),
      },
      monthlabel: LABELMONTH,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filterPerformance.bulan': {
      handler(val) {
        this.getDataPerformance()
      },
    },
  },
  mounted() {
    this.getDataPerformance()
  },
  methods: {
    setDataPerformance(data) {
      this.dataPerformance = data
    },
    formatStartMonth(d) {
      return moment(d).startOf('month').format('YYYY-MM-DD').valueOf()
    },
    formatEndMonth(d) {
      return moment(d).endOf('month').format('YYYY-MM-DD').valueOf()
    },
    async getDataPerformance() {
      const params = {}
      Object.assign(params, {
        start_date: this.formatStartMonth(this.filterPerformance.bulan),
      })
      Object.assign(params, {
        end_date: this.formatEndMonth(this.filterPerformance.bulan),
      })
      await this.$http_komship
        .get('/v1/admin/dashboard/users-growth-performance', {
          params,
        })
        .then(res => this.setDataPerformance(res.data.data))
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: error,
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
  },
}
</script>

<style lang="scss">
@import './User.scss';
</style>

<template>
  <b-row>
    <b-col lg="6">
      <b-card
        no-body
        class="p-1 card__wrapper"
      >
        <template #default>
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-content-between">
                <h4 class="text-black my-0">
                  Performa Transaksi Partner
                </h4>
                <b-button
                  variant="outline"
                  class="button-custom"
                  @click="$router.push('/pendapatan')"
                >
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-content-center">
                <b-form-group class="mr-1">
                  <v-select
                    v-model="filterdata.partner.chart.selectOpt"
                    :options="optionChartPartner"
                    label="name"
                    class="element__vueselect"
                  />
                </b-form-group>
                <div>
                  <vue-monthly-picker
                    v-model="filterdata.partner.chart.bulan"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    place-holder="Pilih Bulan"
                    class="datepickerClass"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <vue-apex-charts
                ref="chartPerformancePartner"
                :options="chartOptionsPartner"
                :series="seriespartner"
                type="area"
                height="400"
              />
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
    <b-col lg="6">
      <b-card
        no-body
        class="p-1 card__wrapper"
      >
        <template #default>
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <h4 class="text-black my-0">
                Top Partner Transaksi
              </h4>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-end">
                <b-form-group class="mr-1">
                  <v-select
                    v-model="filterdata.partner.toplist.selectOpt"
                    :options="optionTopList"
                    label="name"
                    class="element__vueselect"
                  />
                </b-form-group>
                <div>
                  <vue-monthly-picker
                    v-model="filterdata.partner.toplist.bulan"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    place-holder="Pilih Bulan"
                    class="datepickerClass"
                  />
                </div>
              </div>
            </b-col>
            <!-- <b-row> -->
            <b-col
              lg="12"
              style="height: 320px; overflow: auto"
            >
              <b-table
                :items="itemTopPartner"
                :fields="fieldTopPartner"
                show-empty
                empty-text="Tidak ada data yang ditampilkan."
                responsive
                class="mt-1"
              >
                <template #cell(transaction_cod)="data">
                  <strong style="color: #34a770">Rp {{ formatCurrency(data.value) }}</strong>
                </template>
              </b-table>
            </b-col>
            <!-- </b-row> -->
          </b-row>
          <b-overlay
            :show="loading"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <b-card
              v-if="totalTransactionPartner.length !== 0"
              style="
              border: 1px solid #e2e2e2;
              border-radius: 8px;
            "
            >
              <div class="flex items-center">
                <strong
                  class="mr-1 text-black font-bold"
                >Total Nilai Transaksi</strong>
                <img
                  v-b-tooltip.hover.top="'Total nilai transaksi di bulan tersebut'"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                  alt=""
                  variant="outline-primary"
                >
              </div>
              <strong style="color: #34a770; font-size: 30px; font-weight: 600">
                {{ formatCurrency(totalTransactionPartner.grand_total) }}
              </strong>
            </b-card>
          </b-overlay>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import VueMonthlyPicker from 'vue-monthly-picker'
import filtersLibs from '@/libs/filters'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

const seriesNameChart = {
  cod: 'COD',
  noncod: 'Non - COD',
  total: 'Total',
  order: 'Order',
  partner: 'Partner',
}

const typeOfCallingApi = {
  chart: {
    ekspedisi: 'ekspedisi',
    partner: 'partner',
  },
  toplist: {
    ekspedisi: 'ekspedisi',
    partner: 'partner',
  },
}

const colorDefaultChart = ['#08A0F7', '#34A770', '#FBA63C']

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    VueApexCharts,
    BFormGroup,
    vSelect,
    VueMonthlyPicker,
  },
  data() {
    return {
      loading: true,
      seriesekspedisi: [],
      seriespartner: [],
      statusLegend: false,
      chartOptionsEkspedisi: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#000000',
            },
            offsetX: 0,
            formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 14,
          min: this.$moment().startOf('month').valueOf(),
          max: this.$moment().endOf('month').valueOf(),
          categories: [],
          labels: {
            formatter: (val, timestamp) => this.$moment(new Date(timestamp)).format('DD'),
          },
        },
        tooltip: {
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            let htmlRender = ''
            const arrayData = [...w.globals.series]
            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `<p
                  class="my-0 mt-1"
                  style="color: ${colorDefaultChart[idx]};"
                >
                  ${
  w.globals.seriesNames[idx]
}</span> <span class="text-black"> ${filtersLibs.rupiah(
  x[dataPointIndex] || 0,
)}
                </p>`
              }
            })

            return `
            <div
              class="d-grid px-1 rounded align-items-center"
            >
              ${htmlRender}
              <br/>
            </div>
            `
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: colorDefaultChart,
        noData: {
          text: 'NO DATA',
        },
      },
      chartOptionsPartner: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#000000',
            },
            offsetX: 0,
            formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 14,
          min: this.$moment().startOf('month').valueOf(),
          max: this.$moment().endOf('month').valueOf(),
          categories: [],
          labels: {
            formatter: (val, timestamp) => this.$moment(new Date(timestamp)).format('DD'),
          },
        },
        tooltip: {
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            const partner = w.globals.collapsedSeries[1].data[dataPointIndex]
            const order = w.globals.collapsedSeries[0].data[dataPointIndex]

            let htmlRender = ''
            const arrayData = [...w.globals.series]

            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `<p
                  class="my-0 mt-1"
                  style="color: ${colorDefaultChart[idx]};"
                >
                  <span>${
  w.globals.seriesNames[idx]
}</span> <span class="text-black"> ${
  w.globals.seriesNames[idx] === seriesNameChart.order
    ? x[dataPointIndex]
    : filtersLibs.rupiah(x[dataPointIndex] || 0)
}</span>${
  w.globals.seriesNames[idx] === seriesNameChart.total
    ? `<span class="text-gray-600"> <br/>${w.globals.seriesNames[3]} ${order} (${partner} Partner)</span>`
    : ''
}
                </p>`
              }
            })

            return `
            <div
              class="d-grid px-1 rounded align-items-center"
            >
              ${htmlRender}
              <br/>
            </div>
            `
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: [...colorDefaultChart, '#D3067B'],
        noData: {
          text: 'NO DATA',
        },
      },
      optionChartPartner: [
        {
          name: 'Semua',
          value: 0,
        },
      ],
      monthlabel: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Agu',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      bulanChartExpedisi: '2022-02-02',
      filterdata: {
        ekspedisi: {
          chart: {
            selectOpt: {
              name: 'JNE',
              value: 2,
            },
            bulan: this.$moment(),
          },
          toplist: {
            selectOpt: {
              name: 'COD',
              value: 1,
            },
            bulan: this.$moment(),
          },
        },
        partner: {
          chart: {
            selectOpt: {
              name: 'Semua',
              value: 0,
            },
            bulan: this.$moment(),
          },
          toplist: {
            selectOpt: {
              name: 'COD',
              value: 1,
            },
            bulan: this.$moment(),
          },
        },
      },
      itemTopPartner: [],
      datachart: {
        expedisi: [],
        partner: [],
      },
      fieldTopPartner: [
        {
          key: 'partner_name',
          label: 'Partner',
          class: 'bg-white align-middle text-center',
        },
        {
          key: 'total_order',
          label: 'Order',
          class: 'bg-white align-middle text-center',
        },
        {
          key: 'transaction_cod',
          label: 'Nilai Transaksi',
          class: 'bg-white align-middle text-center',
        },
      ],
      totalTransactionPartner: 0,
      datatoplist: {
        ekspedisi: [],
        partner: [],
      },
      optionTopList: [
        {
          name: seriesNameChart.cod,
          value: 1,
        },
        {
          name: seriesNameChart.noncod,
          value: 2,
        },
        {
          name: 'COD & Non COD',
          value: 3,
        },
      ],
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filterdata.ekspedisi.chart.selectOpt': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
      },
    },
    'filterdata.ekspedisi.chart.bulan': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
      },
    },
    'filterdata.partner.chart.selectOpt': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.partner)
      },
    },
    'filterdata.partner.chart.bulan': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.partner)
      },
    },
    'filterdata.partner.toplist.selectOpt': {
      handler(val) {
        this.fetchDataTopPartnerTransaction(typeOfCallingApi.toplist.partner)
      },
    },
    'filterdata.partner.toplist.bulan': {
      handler(val) {
        this.fetchDataTopPartnerTransaction(typeOfCallingApi.toplist.partner)
      },
    },
  },
  mounted() {
    this.getDataShippingName()
  },
  methods: {
    formatStartMonth(d) {
      return moment(d).startOf('month').format('YYYY-MM-DD').valueOf()
    },
    formatEndMonth(d) {
      return moment(d).endOf('month').format('YYYY-MM-DD').valueOf()
    },
    async getDataShippingName() {
      await this.$http_komship
        .get('/v1/admin/dashboard/shippingName')
        .then(({ data }) => {
          const oriOptions = data.data.map((x, idx) => ({
            name: x,
            value: idx + 1,
          }))
          const optPartner = [...oriOptions]
          optPartner.unshift({
            name: 'Semua',
            value: 0,
          })
          this.optionChartEkspedisi = oriOptions
          this.optionChartPartner = optPartner
          this.$nextTick(() => {
            this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
            this.fetchDataChart(typeOfCallingApi.chart.partner)
            // this.fetchDataTopShippingExpeditors(
            //   typeOfCallingApi.toplist.ekspedisi,
            // )
            this.fetchDataTopPartnerTransaction(
              typeOfCallingApi.toplist.partner,
            )
          })
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    clickLegendPartner(event, chartContext, config) {
      if (config.globals.collapsedSeriesIndices.indexOf(3) !== -1) {
        this.statusLegend = true
        this.$refs.chartPerformancePartner.chart.hideSeries(
          seriesNameChart.noncod,
        )
        this.$refs.chartPerformancePartner.chart.hideSeries(
          seriesNameChart.total,
        )
        this.$refs.chartPerformancePartner.chart.hideSeries(seriesNameChart.cod)
        this.$refs.chartPerformancePartner.chart.hideSeries(
          seriesNameChart.partner,
        )
        this.$refs.chartPerformancePartner.chart.showSeries(
          seriesNameChart.order,
        )
      } else {
        this.$refs.chartPerformancePartner.chart.showSeries(
          seriesNameChart.noncod,
        )
        this.$refs.chartPerformancePartner.chart.showSeries(
          seriesNameChart.total,
        )
        this.$refs.chartPerformancePartner.chart.showSeries(seriesNameChart.cod)
        this.$refs.chartPerformancePartner.chart.hideSeries(
          seriesNameChart.partner,
        )
        this.$refs.chartPerformancePartner.chart.hideSeries(
          seriesNameChart.order,
        )
      }
    },
    async fetchDataTopPartnerTransaction(type = '') {
      this.loading = true
      let endpoint = ''
      let dtbulan = ''
      const params = {}
      switch (type.toLowerCase()) {
        case typeOfCallingApi.toplist.partner:
          endpoint = 'topPartnerTransaction'
          dtbulan = this.filterdata.partner.toplist.bulan
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.payment_option = this.filterdata.partner.toplist.selectOpt.value
          break
        default:
          break
      }
      await this.$http_komship
        .get(`/v1/admin/dashboard/${endpoint}`, { params })
        .then(({ data }) => {
          this.itemTopPartner = data.data.partners
          this.totalTransactionPartner = data.data
          this.datatoplist[type.toLowerCase()] = data.data
          this.loading = false
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    async fetchDataChart(type = '') {
      let endpoint = ''
      let dtbulan = ''
      const params = {}
      switch (type.toLowerCase()) {
        case typeOfCallingApi.chart.partner:
          endpoint = 'partnerTransactionPerformance'
          dtbulan = this.filterdata.partner.chart.bulan
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.expedition_option = this.filterdata.partner.chart.selectOpt.name.toLowerCase()
            === 'semua'
            ? this.optionChartEkspedisi.map(x => x.name).join()
            : this.filterdata.partner.chart.selectOpt.name
          this.chartOptionsPartner = {
            ...this.chartOptionsPartner,
            xaxis: {
              ...this.chartOptionsPartner.xaxis,
              min: this.$moment(dtbulan).startOf('month').valueOf(),
              max: this.$moment(dtbulan).endOf('month').valueOf(),
            },
          }
          break
        case typeOfCallingApi.chart.ekspedisi:
          endpoint = 'shippingPerformancePerExpedition'
          dtbulan = this.filterdata.ekspedisi.chart.bulan
          this.chartOptionsEkspedisi = {
            ...this.chartOptionsEkspedisi,
            xaxis: {
              ...this.chartOptionsEkspedisi.xaxis,
              min: this.$moment(dtbulan).startOf('month').valueOf(),
              max: this.$moment(dtbulan).endOf('month').valueOf(),
            },
          }
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.expedition_option = this.filterdata.ekspedisi.chart.selectOpt.name
          break
        default:
          break
      }
      await this.$http_komship
        .get(`/v1/admin/dashboard/${endpoint}`, { params })
        .then(({ data }) => {
          switch (type) {
            case typeOfCallingApi.chart.ekspedisi:
              this[`series${type}`] = [
                {
                  name: seriesNameChart.total,
                  data: data.data.total.map(x => x.total),
                },
                {
                  name: seriesNameChart.noncod,
                  data: data.data.non_cod.map(x => x.total_noncod),
                },
                {
                  name: seriesNameChart.cod,
                  data: data.data.cod.map(x => x.total_cod),
                },
              ]
              this.chartOptionsEkspedisi = {
                ...this.chartOptionsEkspedisi,
                xaxis: {
                  ...this.chartOptionsEkspedisi.xaxis,
                  categories: data.data.total.map(x => x.day),
                },
              }
              break
            case typeOfCallingApi.chart.partner:
              this[`series${type}`] = [
                {
                  name: seriesNameChart.total,
                  data: data.data.total.map(x => x.total),
                },
                {
                  name: seriesNameChart.noncod,
                  data: data.data.non_cod.map(x => x.total_noncod),
                },
                {
                  name: seriesNameChart.cod,
                  data: data.data.cod.map(x => x.total_cod),
                },
                {
                  name: seriesNameChart.order,
                  data: data.data.total.map(x => x.total_order),
                },
                {
                  name: seriesNameChart.partner,
                  data: data.data.total.map(x => x.total_partner),
                },
              ]
              this.chartOptionsPartner = {
                ...this.chartOptionsPartner,
                xaxis: {
                  ...this.chartOptionsPartner.xaxis,
                  categories: data.data.total.map(x => x.day),
                },
              }
              this.$nextTick(() => {
                this.$refs.chartPerformancePartner.chart.hideSeries(
                  seriesNameChart.order,
                )
                this.$refs.chartPerformancePartner.chart.hideSeries(
                  seriesNameChart.partner,
                )
              })
              break
            default:
              break
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    parseStartDate(dt) {
      return this.$moment(dt).startOf('month').format('YYYY-MM-DDTHH:mm:ss\\Z')
    },
    parseEndDate(dt) {
      let data = null
      if (this.$moment(dt).month() === this.$moment().month()) {
        data = this.$moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss\\Z')
      } else {
        data = this.$moment(dt).endOf('month').format('YYYY-MM-DDTHH:mm:ss\\Z')
      }
      return data
    },
    formatCurrency(dt) {
      return new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(dt)
    },
  },
}
</script>

<template>
  <div>
    <strong
      class="text-black font-bold"
      style="font-size: 18px"
    >
      Ekspedisi
    </strong>
    <BRow class="mt-1">
      <BCol lg="6">
        <BCard style="border: 1px solid #e2e2e2; border-radius: 14px">
          <strong
            class="text-black font-bold"
          >Performa Succsess Rate Ekspedisi</strong>
          <VueMonthlyPicker
            v-model="filterSuccessRate.bulan"
            date-format="MMM YYYY"
            place-holder="30 Hari Terakhir"
            class="filterSuccessRate"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            style="width: 50px; margin-top: 5px"
          />
          <BOverlay
            :show="isLoadingSuccessRate"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="itemSuccessHandling"
              :fields="fieldSuccessHandling"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1"
            >
              <template #cell(status)="data">
                <div
                  :class="handleClassStatus(data)"
                  style="border-radius: 20px; text-align: center"
                >
                  {{ handleLabelStatus(data) }}
                </div>
              </template>
              <template #cell(retur_rate)="data">
                <span :class="handleLableReturRate(data)">{{ handleReturRate(data.item) }}</span>
              </template>
              <template #cell(success_rate)="data">
                <span>{{ handleSuccessRate(data.item) }}</span>
              </template>
            </BTable>
          </BOverlay>
        </BCard>
      </BCol>
      <BCol lg="6">
        <BCard style="border: 1px solid #e2e2e2; border-radius: 14px">
          <strong class="text-black font-bold">Performa Kecepatan Pengiriman Ekspedisi</strong>
          <VueMonthlyPicker
            v-model="filterFastHandilng.bulan"
            date-format="MMM YYYY"
            place-holder="30 Hari Terakhir"
            class="filterSuccessRate"
            :month-labels="monthlabel"
            :max="maxDatePicker"
            style="width: 50px; margin-top: 5px"
          />
          <BOverlay
            :show="isLoadingFastHandling"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="itemFastHandling"
              :fields="fieldFastHandling"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1"
            >
              <template #cell(cod)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(non_cod)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(average)="data">
                <span>{{ data.value }} hari</span>
              </template>
              <template #cell(service)="data">
                <span>{{ handleService(data) }}</span>
              </template>
            </BTable>
          </BOverlay>
        </BCard>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
} from 'bootstrap-vue'
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import isEmpty from 'lodash/isEmpty'
import { LABELMONTH } from '@/libs/filterDate'
import { fieldSuccessHandling, fieldFastHandling } from './config'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    VueMonthlyPicker,
  },
  data() {
    return {
      itemSuccessHandling: [],
      itemFastHandling: [],
      fieldSuccessHandling,
      fieldFastHandling,
      monthlabel: LABELMONTH,
      filterSuccessRate: {
        bulan: this.$moment(),
      },
      filterFastHandilng: {
        bulan: this.$moment(),
      },
      isLoadingSuccessRate: true,
      isLoadingFastHandling: true,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment()
        .endOf('month')
    },
  },
  watch: {
    'filterSuccessRate.bulan': {
      handler() {
        this.getSuccessRateShipping()
      },
    },
    'filterFastHandilng.bulan': {
      handler() {
        this.getShippingDeliveryPerformance()
      },
    },
  },
  mounted() {
    this.getSuccessRateShipping()
    this.getShippingDeliveryPerformance()
  },
  methods: {
    formatStartMonth(d) {
      return moment(d)
        .startOf('month')
        .format('YYYY-MM-DD')
        .valueOf()
    },
    formatEndMonth(d) {
      return moment(d)
        .endOf('month')
        .format('YYYY-MM-DD')
        .valueOf()
    },
    async getSuccessRateShipping() {
      this.isLoadingSuccessRate = true
      const params = {}
      Object.assign(params, {
        start_date: this.formatStartMonth(this.filterSuccessRate.bulan),
      })
      Object.assign(params, {
        end_date: this.formatEndMonth(this.filterSuccessRate.bulan),
      })
      await this.$http_komship
        .get('/v1/admin/dashboard/success-rate-shipping', {
          params,
        })
        .then(res => {
          const { data } = res.data
          this.itemSuccessHandling = data
          this.isLoadingSuccessRate = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: error.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoadingSuccessRate = false
        })
    },
    async getShippingDeliveryPerformance() {
      this.isLoadingFastHandling = true
      const params = {}
      Object.assign(params, {
        start_date: this.formatStartMonth(this.filterFastHandilng.bulan),
      })
      Object.assign(params, {
        end_date: this.formatEndMonth(this.filterFastHandilng.bulan),
      })
      await this.$http_komship
        .get('/v1/admin/dashboard/shipping-delivery-performance', {
          params,
        })
        .then(res => {
          const { data } = res.data
          this.itemFastHandling = data
          this.isLoadingFastHandling = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: error.message,
              variant: 'danger',
            },
          }, 2000)
          this.isLoadingFastHandling = false
        })
    },
    handleReturRate(value) {
      const data = (value.total_retur / value.total_order) * 100
      return `${data.toFixed(1)}% (${value.total_retur}/${value.total_order})`
    },
    handleSuccessRate(value) {
      const data = (value.total_diterima / value.total_order) * 100
      return `${data.toFixed(1)}% (${value.total_diterima}/${value.total_order})`
    },
    handleLableReturRate(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'very-good'
      }
      if (data <= 2) {
        return 'very-good'
      }
      if (data >= 2 && data <= 5) {
        return 'good'
      }
      if (data >= 5 && data <= 15) {
        return 'medium'
      }
      if (data >= 15 && data <= 25) {
        return 'bad'
      }
      if (data >= 25) {
        return 'very-bad'
      }
      return 'very-good'
    },
    handleClassStatus(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'lableStatusVeryGood'
      }
      if (data <= 2) {
        return 'lableStatusVeryGood'
      }
      if (data >= 2 && data <= 5) {
        return 'lableStatusGood'
      }
      if (data >= 5 && data <= 15) {
        return 'lableStatusMedium'
      }
      if (data >= 15 && data <= 25) {
        return 'lableStatusBad'
      }
      if (data >= 25) {
        return 'lableStatusVeryBad'
      }
      return 'lableStatusVeryGood'
    },
    handleLabelStatus(value) {
      const data = (value.item.total_retur / value.item.total_order) * 100
      if (!isEmpty(data)) {
        return 'Sangat bagus'
      }
      if (data <= 2) {
        return 'Sangat bagus'
      }
      if (data >= 2 && data <= 5) {
        return 'Bagus'
      }
      if (data >= 5 && data <= 15) {
        return 'Sedang'
      }
      if (data >= 15 && data <= 25) {
        return 'Buruk'
      }
      if (data >= 25) {
        return 'Sangat buruk'
      }
      return 'Sangat bagus'
    },
    handleService(value) {
      // const data = value.item.service
      const { service } = value.item
      if (service === 'REG19') {
        return service.slice(0, 3)
      }
      if (service === 'UDRREG') {
        return 'REGULER'
      }
      if (service === 'DRGREG') {
        return 'CARGO'
      }
      return service
    },
  },
}
</script>

<style lang="scss" scoped src="./Ekspedisi.scss" />

<template>
  <BCol lg="12">
    <BCard>
      <div class="flex">
        <strong
          class="text-black font-bold mr-1"
          style="font-size: 18px"
        >
          {{ title }}
        </strong>
        <img
          v-b-tooltip.hover.top="'Data dalam 150 hari terakhir'"
          src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
          alt="Komerce"
          class="cursor-pointer"
          variant="outline-primary"
        >
      </div>
      <BRow class="mt-1">
        <BCol
          lg="6"
          class="flex items-center justify-between"
        >
          <div
            v-for="(data, idx) in indicator"
            :key="idx"
            class="flex"
          >
            <img
              :src="data.img"
              alt="Komerce"
              style="margin-right: 5px"
            >
            <span class="text-black">{{ data.name }}</span>
          </div>
        </BCol>
      </BRow>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="itemRasioRetur"
          :fields="fieldRasioRetur"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
        >
          <template #cell(jne)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'JNE'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(ideexpress)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'IDEXPRESS'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(sicepat)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'SICEPAT'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(sap)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'SAP'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(ninja)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'NINJA'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(j&t)="data">
            <div
              v-for="(item, idx) in data.item.data"
              :key="idx"
            >
              <div
                v-if="item.ekspedisi === 'J&T'"
                class="flex items-center justify-evenly"
              >
                <img
                  :src="handleStatusIndicator(item.status)"
                  alt="Komerce"
                  class="mr-1"
                >
                <span>{{ item.percentage }} % ({{ `${item.retur}/${item.total}` }})</span>
              </div>
            </div>
          </template>
          <template #cell(average)="data">
            {{ data.item.average }} %
          </template>
          <template #cell(status)="data">
            <div
              :class="handleClassStatus(data.item.status)"
              style="border-radius: 20px"
            >
              {{ data.item.status }}
            </div>
          </template>
        </BTable>
      </BOverlay>
      <div
        style="color: #08a0f7; text-align: center; cursor: pointer"
        @click="handleToMoreHistoryRetur"
      >
        Lihat Selengkapnya
      </div>
    </BCard>
  </BCol>
</template>

<script>
import {
  BCol, BCard, BTable, BOverlay,
} from 'bootstrap-vue'
import IconVeryGood from '@/assets/images/svg/very-good.svg'
import IconGood from '@/assets/images/svg/good.svg'
import IconMedium from '@/assets/images/svg/medium.svg'
import IconBad from '@/assets/images/svg/bad.svg'
import IconVeryBad from '@/assets/images/svg/very-bad.svg'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { indicatorRatioRetur, fieldRasioRetur } from '@/views/pages/dashboard-admin-1.1/components/config'

export default {
  components: {
    BCol,
    BCard,
    BTable,
    BOverlay,
  },
  data() {
    return {
      title: 'History Rasio Retur Kota & Kabupaten',
      indicator: indicatorRatioRetur,
      itemRasioRetur: [],
      fieldRasioRetur,
      loading: true,
    }
  },
  mounted() {
    this.getWidgetRasioRetur()
  },
  methods: {
    async getWidgetRasioRetur() {
      this.loading = true
      await this.$http_komship
        .get('/v1/admin/dashboard/widget-ratio-return')
        .then(res => {
          const { data } = res.data
          this.itemRasioRetur = data
          this.loading = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: error,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },
    handleToMoreHistoryRetur() {
      this.$router.push({
        path: '/analitics-returnarea',
      })
    },
    handleStatusIndicator(value) {
      if (value === 'Sangat Buruk') {
        return IconVeryBad
      }
      if (value === 'Buruk') {
        return IconBad
      }
      if (value === 'Sedang') {
        return IconMedium
      }
      if (value === 'Bagus') {
        return IconGood
      }
      if (value === 'Sangat Bagus') {
        return IconVeryGood
      }
      return IconVeryGood
    },
    handleClassStatus(value) {
      if (value === 'Sangat Bagus') {
        return 'lableStatusVeryGood'
      }
      if (value === 'Bagus') {
        return 'lableStatusGood'
      }
      if (value === 'Sedang') {
        return 'lableStatusMedium'
      }
      if (value === 'Buruk') {
        return 'lableStatusBad'
      }
      if (value === 'Sangat Buruk') {
        return 'lableStatusVeryBad'
      }
      return 'lableStatusVeryGood'
    },
  },
}
</script>
<style lang="scss" scoped src="./Ekspedisi.scss" />

<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h2 class="text-black font-bold">
          Dashboard
        </h2>
        <User />
        <b-card
          class="mt-1"
          style="border-radius: 8px"
        >
          <ekspedisi />
          <ongkir />
        </b-card>
      </b-col>
      <history-retur />
      <Partner />
    </b-row>
  </div>
</template>

<script>
import User from './User.vue'
import ekspedisi from './Ekspedisi.vue'
import ongkir from './Ongkir.vue'
import HistoryRetur from './HistoryRetur.vue'
import Partner from './Partner.vue'

export default {
  components: {
    User,
    ekspedisi,
    ongkir,
    HistoryRetur,
    Partner,
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .element__vueselect .vs__dropdown-toggle {
    height: 31px !important;
  }
  .element__vueselect .vs__selected {
    margin-top: 0px !important;
  }
</style>

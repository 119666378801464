<template>
  <b-row>
    <b-col lg="6">
      <b-card
        no-body
        class="p-1 card__wrapper"
        style="border: 1px solid #e2e2e2; border-radius: 14px"
      >
        <template #default>
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-content-between">
                <h4 class="text-black my-0">
                  Performa Ongkir Ekspedisi
                </h4>
                <b-button
                  variant="outline"
                  class="button-custom"
                  @click="$router.push('/performa-ekspedisi')"
                >
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <div class="d-flex justify-content-center">
                <b-form-group class="mr-1">
                  <v-select
                    v-model="filterdata.ekspedisi.chart.selectOpt"
                    :options="optionChartEkspedisi"
                    label="name"
                    class="element__vueselect"
                  />
                </b-form-group>
                <div>
                  <vue-monthly-picker
                    v-model="filterdata.ekspedisi.chart.bulan"
                    :month-labels="monthlabel"
                    :max="maxDatePicker"
                    date-format="MMM YYYY"
                    place-holder="Pilih Bulan"
                    class="datepickerClass"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <vue-apex-charts
                type="area"
                height="400"
                :options="chartOptionsEkspedisi"
                :series="seriesekspedisi"
              />
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
    <b-col lg="6">
      <b-card
        no-body
        class="p-1 card__wrapper"
        style="border: 1px solid #e2e2e2; border-radius: 14px"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-1"
          >
            <h4 class="text-black my-0">
              Top Ongkir Ekspedisi
            </h4>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-form-group class="mr-1">
                <v-select
                  v-model="filterdata.ekspedisi.toplist.selectOpt"
                  :options="optionTopList"
                  label="name"
                  class="element__vueselect"
                />
              </b-form-group>
              <div>
                <vue-monthly-picker
                  v-model="filterdata.ekspedisi.toplist.bulan"
                  :month-labels="monthlabel"
                  :max="maxDatePicker"
                  date-format="MMM YYYY"
                  place-holder="Pilih Bulan"
                  class="datepickerClass"
                  style="height: 38px"
                />
              </div>
            </div>
          </b-col>
          <b-table
            :items="itemTopOngkir"
            :fields="fieldTopOngkir"
            show-empty
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            class="mt-1"
          >
            <template #cell(shipping_cost)="data">
              <strong style="color: #34a770">{{
                formatCurrency(data.value)
              }}</strong>
            </template>
          </b-table>
        </b-row>
        <b-card
          v-if="totalShippingCost.total_shipping_cost !== 0"
          style="
            border: 1px solid #e2e2e2;
            border-radius: 8px;
            margin: 0;
            margin-top: 10rem;
          "
        >
          <div class="flex items-center">
            <strong
              class="mr-1 text-black font-bold"
            >Total Nilai Ongkir</strong>
            <img
              v-b-tooltip.hover.top="'Total nilai ongkir di bulan tersebut'"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt=""
              variant="outline-primary"
            >
          </div>
          <strong style="color: #34a770; font-size: 30px; font-weight: 600">
            {{ formatCurrency(totalShippingCost.total_shipping_cost) }}
          </strong>
        </b-card>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import filtersLibs from '@/libs/filters'
import VSelect from 'vue-select'
import VueMonthlyPicker from 'vue-monthly-picker'
import VueApexCharts from 'vue-apexcharts'
import {
  BRow, BCol, BCard, BButton, BFormGroup,
} from 'bootstrap-vue'

const typeOfCallingApi = {
  chart: {
    ekspedisi: 'ekspedisi',
    partner: 'partner',
  },
  toplist: {
    ekspedisi: 'ekspedisi',
    partner: 'partner',
  },
}

const seriesNameChart = {
  cod: 'COD',
  noncod: 'Non - COD',
  total: 'Total',
  order: 'Order',
  partner: 'Partner',
}

const colorDefaultChart = ['#08A0F7', '#34A770', '#FBA63C']

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    VSelect,
    VueMonthlyPicker,
    VueApexCharts,
  },
  data() {
    return {
      filterdata: {
        ekspedisi: {
          chart: {
            selectOpt: {
              name: 'JNE',
              value: 2,
            },
            bulan: this.$moment(),
          },
          toplist: {
            selectOpt: {
              name: 'COD',
              value: 1,
            },
            bulan: this.$moment(),
          },
        },
        partner: {
          chart: {
            selectOpt: {
              name: 'Semua',
              value: 0,
            },
            bulan: this.$moment(),
          },
          toplist: {
            selectOpt: {
              name: 'COD',
              value: 1,
            },
            bulan: this.$moment(),
          },
        },
      },
      optionChartEkspedisi: [],
      monthlabel: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Agu',
        'Sep',
        'Okt',
        'Nov',
        'Dec',
      ],
      seriesekspedisi: [],
      datatoplist: {
        ekspedisi: [],
        partner: [],
      },
      optionTopList: [
        {
          name: seriesNameChart.cod,
          value: 1,
        },
        {
          name: seriesNameChart.noncod,
          value: 2,
        },
        {
          name: 'COD & Non COD',
          value: 3,
        },
      ],
      itemTopOngkir: [],
      datachart: {
        expedisi: [],
        partner: [],
      },
      fieldTopOngkir: [
        {
          key: 'shipping',
          label: 'Ekspedisi',
          class: 'bg-white align-middle text-center',
        },
        {
          key: 'total_order',
          label: 'Order',
          class: 'bg-white align-middle text-center',
        },
        {
          key: 'shipping_cost',
          label: 'Nilai Ongkir',
          class: 'bg-white align-middle text-center',
        },
      ],
      totalShippingCost: {},
      chartOptionsEkspedisi: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#000000',
            },
            offsetX: 0,
            formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 14,
          min: this.$moment().startOf('month').valueOf(),
          max: this.$moment().endOf('month').valueOf(),
          categories: [],
          labels: {
            formatter: (val, timestamp) => this.$moment(new Date(timestamp)).format('DD'),
          },
        },
        tooltip: {
          // intersect: true,
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            let htmlRender = ''
            const arrayData = [...w.globals.series]
            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `<p
                  class="my-0 mt-1"
                  style="color: ${colorDefaultChart[idx]};"
                >
                  ${
  w.globals.seriesNames[idx]
}</span> <span class="text-black"> ${filtersLibs.rupiah(
  x[dataPointIndex] || 0,
)}
                </p>`
              }
            })

            return `
            <div
              class="d-grid px-1 rounded align-items-center"
            >
              ${htmlRender}
              <br/>
            </div>
            `
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: colorDefaultChart,
        noData: {
          text: 'NO DATA',
        },
      },
      chartOptionsPartner: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#000000',
            },
            offsetX: 0,
            formatter: val => `${(val / 1000000).toFixed(0)} Jt`,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 14,
          min: this.$moment()
            .startOf('month')
            .valueOf(),
          max: this.$moment()
            .endOf('month')
            .valueOf(),
          categories: [],
          labels: {
            formatter: (val, timestamp) => this.$moment(new Date(timestamp)).format('DD'),
          },
        },
        tooltip: {
          // intersect: true,
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            // w.globals.collapsedSeriesIndices: Array number
            // w.globals.collapsedSeries: Array Object
            /*
              {data: Array(7) [ 200, 400, 450, … ]
              index: 3
              type: "area"}
            */

            const partner = w.globals.collapsedSeries[1].data[dataPointIndex]
            const order = w.globals.collapsedSeries[0].data[dataPointIndex]

            let htmlRender = ''
            const arrayData = [...w.globals.series]

            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `<p
                  class="my-0 mt-1"
                  style="color: ${colorDefaultChart[idx]};"
                >
                  <span>${
  w.globals.seriesNames[idx]
}</span> <span class="text-black"> ${
  w.globals.seriesNames[idx] === seriesNameChart.order
    ? x[dataPointIndex]
    : filtersLibs.rupiah(x[dataPointIndex] || 0)
}</span>${
  w.globals.seriesNames[idx] === seriesNameChart.total
    ? `<span class="text-gray-600"> <br/>${w.globals.seriesNames[3]} ${order} (${partner} Partner)</span>`
    : ''
}
                </p>`
              }
            })

            return `
            <div
              class="d-grid px-1 rounded align-items-center"
            >
              ${htmlRender}
              <br/>
            </div>
            `
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        colors: [...colorDefaultChart, '#D3067B'],
        noData: {
          text: 'NO DATA',
        },
      },
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    'filterdata.ekspedisi.chart.selectOpt': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
      },
    },
    'filterdata.ekspedisi.chart.bulan': {
      handler(val) {
        this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
      },
    },
    'filterdata.ekspedisi.toplist.selectOpt': {
      handler(val) {
        this.fetchDataTopShippingExpeditors(typeOfCallingApi.toplist.ekspedisi)
      },
    },
    'filterdata.ekspedisi.toplist.bulan': {
      handler(val) {
        this.fetchDataTopShippingExpeditors(typeOfCallingApi.toplist.ekspedisi)
      },
    },
    'filterdata.partner.toplist.selectOpt': {
      handler(val) {
        this.fetchDataTopPartnerTransaction(typeOfCallingApi.toplist.partner)
      },
    },
    'filterdata.partner.toplist.bulan': {
      handler(val) {
        this.fetchDataTopPartnerTransaction(typeOfCallingApi.toplist.partner)
      },
    },
  },
  mounted() {
    this.getDataShippingName()
  },
  methods: {
    async fetchDataTopShippingExpeditors(type = '') {
      let endpoint = ''
      let dtbulan = ''
      const params = {}
      switch (type.toLowerCase()) {
        case typeOfCallingApi.toplist.ekspedisi:
          endpoint = 'topShippingExpeditors'
          dtbulan = this.filterdata.ekspedisi.toplist.bulan
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.payment_option = this.filterdata.ekspedisi.toplist.selectOpt.value
          break
        default:
          break
      }
      await this.$http_komship
        .get(`/v1/admin/dashboard/${endpoint}`, { params })
        .then(({ data }) => {
          this.itemTopOngkir = data.data
          this.totalShippingCost = data
          this.datatoplist[type.toLowerCase()] = data.data
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async getDataShippingName() {
      await this.$http_komship
        .get('/v1/admin/dashboard/shippingName')
        .then(({ data }) => {
          const oriOptions = data.data.map((x, idx) => ({
            name: x,
            value: idx + 1,
          }))
          const optPartner = [...oriOptions]
          optPartner.unshift({
            name: 'Semua',
            value: 0,
          })
          this.optionChartEkspedisi = oriOptions
          this.optionChartPartner = optPartner
          this.$nextTick(() => {
            this.fetchDataChart(typeOfCallingApi.chart.ekspedisi)
            this.fetchDataChart(typeOfCallingApi.chart.partner)
            this.fetchDataTopShippingExpeditors(
              typeOfCallingApi.toplist.ekspedisi,
            )
          })
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async fetchDataChart(type = '') {
      // payment_option 1: COD, 2: non COD, 3: total(cod dan non-cod)
      // start_date => awal bulan tgl 1 00:00 contoh 2022-02-01T00:00:00
      // end_date => skrng 23:59 contoh 2022-02-28 23:59:59
      let endpoint = ''
      let dtbulan = ''
      const params = {}
      switch (type.toLowerCase()) {
        case typeOfCallingApi.chart.partner:
          endpoint = 'partnerTransactionPerformance'
          dtbulan = this.filterdata.partner.chart.bulan
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.expedition_option = this.filterdata.partner.chart.selectOpt.name.toLowerCase()
            === 'semua'
            ? this.optionChartEkspedisi.map(x => x.name).join()
            : this.filterdata.partner.chart.selectOpt.name
          this.chartOptionsPartner = {
            ...this.chartOptionsPartner,
            xaxis: {
              ...this.chartOptionsPartner.xaxis,
              min: this.$moment(dtbulan).startOf('month').valueOf(),
              max: this.$moment(dtbulan).endOf('month').valueOf(),
            },
          }
          break
        case typeOfCallingApi.chart.ekspedisi:
          endpoint = 'shippingPerformancePerExpedition'
          dtbulan = this.filterdata.ekspedisi.chart.bulan
          this.chartOptionsEkspedisi = {
            ...this.chartOptionsEkspedisi,
            xaxis: {
              ...this.chartOptionsEkspedisi.xaxis,
              min: this.$moment(dtbulan).startOf('month').valueOf(),
              max: this.$moment(dtbulan).endOf('month').valueOf(),
            },
          }
          params.start_date = this.parseStartDate(dtbulan)
          params.end_date = this.parseEndDate(dtbulan)
          params.expedition_option = this.filterdata.ekspedisi.chart.selectOpt.name
          break
        default:
          break
      }
      await this.$http_komship
        .get(`/v1/admin/dashboard/${endpoint}`, { params })
        .then(({ data }) => {
          switch (type) {
            case typeOfCallingApi.chart.ekspedisi:
              this[`series${type}`] = [
                {
                  name: seriesNameChart.total,
                  data: data.data.total.map(x => x.total),
                },
                {
                  name: seriesNameChart.noncod,
                  data: data.data.non_cod.map(x => x.total_noncod),
                },
                {
                  name: seriesNameChart.cod,
                  data: data.data.cod.map(x => x.total_cod),
                },
              ]
              this.chartOptionsEkspedisi = {
                ...this.chartOptionsEkspedisi,
                xaxis: {
                  ...this.chartOptionsEkspedisi.xaxis,
                  categories: data.data.total.map(x => x.day),
                },
              }
              break
            case typeOfCallingApi.chart.partner:
              this[`series${type}`] = [
                {
                  name: seriesNameChart.total,
                  data: data.data.total.map(x => x.total),
                },
                {
                  name: seriesNameChart.noncod,
                  data: data.data.non_cod.map(x => x.total_noncod),
                },
                {
                  name: seriesNameChart.cod,
                  data: data.data.cod.map(x => x.total_cod),
                },
                {
                  name: seriesNameChart.order,
                  data: data.data.total.map(x => x.total_order),
                },
                {
                  name: seriesNameChart.partner,
                  data: data.data.total.map(x => x.total_partner),
                },
              ]
              this.chartOptionsPartner = {
                ...this.chartOptionsPartner,
                xaxis: {
                  ...this.chartOptionsPartner.xaxis,
                  categories: data.data.total.map(x => x.day),
                },
              }
              // this.$nextTick(() => {
              //   this.$refs.chartPerformancePartner.chart.hideSeries(
              //     seriesNameChart.order,
              //   )
              //   this.$refs.chartPerformancePartner.chart.hideSeries(
              //     seriesNameChart.partner,
              //   )
              // })
              break
            default:
              break
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal untuk mengambil data, silahkan coba lagi!',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    parseStartDate(dt) {
      return this.$moment(dt)
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ss\\Z')
    },
    parseEndDate(dt) {
      let data = null
      if (this.$moment(dt).month() === this.$moment().month()) {
        data = this.$moment()
          .endOf('day')
          .format('YYYY-MM-DDTHH:mm:ss\\Z')
      } else {
        data = this.$moment(dt)
          .endOf('month')
          .format('YYYY-MM-DDTHH:mm:ss\\Z')
      }
      return data
    },
    formatCurrency(dt) {
      return filtersLibs.rupiah(dt)
    },
  },
}
</script>

<style lang="scss">
.card__wrapper {
  border-radius: 16px;
  height: 550px;
  &--toplist {
    height: 420px;
    overflow: hidden;
    &:hover {
      overflow-y: scroll;
    }
  }
}
.button-custom {
  padding: 0;
  margin: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  border: 1.5px solid #222222;
}
</style>
